<template>
  <div>
    <!-- 宽带套餐 -->
    <area-tree @setQueryParams="handleQuery" :treeShow="url ? false : true">
      <!-- 搜索栏 -->
      <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
        <el-form-item label="套餐状态:"  >
          <el-select v-model="queryParams.status" @change="refresh" clearable filterable placeholder="套餐状态" style="width:100px">
            <el-option label="全部" value="" ></el-option>
            <el-option label="上架中" :value="1"  ></el-option>
            <el-option label="已下架" :value="0" ></el-option>
          </el-select>
        </el-form-item> 
        <el-form-item>
          <el-select v-model="queryParams.operator" placeholder="请选择运营商" clearable filterable @change="refresh">
            <el-option label="中国移动" value="中国移动"></el-option>
            <el-option label="中国联通" value="中国联通"></el-option>
            <el-option label="中国电信" value="中国电信"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
            <el-input placeholder="请输入内容" v-model="select2"  >
            <el-select v-model="select1"  slot="prepend" placeholder="请选择" style="width:100px">
              <el-option label="套餐名称" value="1"></el-option>
              <el-option label="学校ID" value="2"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        
        <el-form-item>
          <el-button type="success" icon="el-icon-search" @click="select" >搜索</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="success" @click="handleDetails()">推广套餐</el-button>
        </el-form-item> -->
        <el-form-item>
          <el-button type="success" v-if="!this.isChecked" @click="openCheckedUp" >批量上架</el-button>
        </el-form-item>
          <el-form-item>
          <el-button type="success" v-if="!this.isChecked" @click="openCheckedDown">批量下架</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" v-if="this.isChecked" @click="enterChecked" >完成</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" v-if="this.isChecked" @click="closeChecked">取消</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)" >添加套餐</el-button>
        </el-form-item>
      </el-form>
      <!-- 添加 -->
      <div class="content-wrap">
        <!--列表-->
        <paged-table
          :data="broadBandList"
          :total="broadBandTotal"
          :loading="tLoading"
          :isShowSelection="false"
          defaultSortProp="id"
          v-model="queryParams"
          @refresh="refresh"
          @selectionChange="handleSelectionChange">
          >
          <el-table-column
            v-if = this.isChecked 
            type="selection"
            align="center"
            width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="id"
            label="ID"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="extend.schoolName"
            label="学校"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="operator"
            label="运营商"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="套餐名称" width="170px"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="bandWidth"
            label="带宽"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="keyWord"
            label="热点编号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="mealType"
            label="套餐类型"
            :show-overflow-tooltip="true"
          >
          <template slot-scope="scope">
              <span v-if="scope.row.mealType == 1">宽带套餐</span>
              <span v-if="scope.row.mealType == 2">融合套餐</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="img" label="图标" height="100" align="center">
            <template slot-scope="scope">
              <i class="el-icon-picture" @click="downloadPath(scope.row.img)" style="font-size:30px; cursor:pointer" title="点击下载查看"></i>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="status"
            label="套餐状态"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">已下架</span>
              <span v-if="scope.row.status == 1">上架中</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="spreadStatus"
            label="分销状态"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.spreadStatus == 0">否</span>
              <span v-if="scope.row.spreadStatus == 1">是</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastTime"
            label="套餐时长"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
            <el-table-column
              prop="label"
              label="标签"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            
          <el-table-column
            prop="price"
            label="原价(元)"
            :show-overflow-tooltip="true"
          >
            <span slot-scope="scope">{{ (scope.row.price/100).toFixed(2) }}</span>
          </el-table-column>
          <!-- <el-table-column prop="detailImg" label="详情图片" height="100" align="center">
            <template slot-scope="scope">
              <i class="el-icon-picture" @click="downloadPath(scope.row.detailImg)" style="font-size:30px; cursor:pointer" title="点击下载查看"></i>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="realyPrice"
            label="售价(元)"
            :show-overflow-tooltip="true"
          >
            <span slot-scope="scope">{{ (scope.row.realyPrice/100).toFixed(2) }}</span>
          </el-table-column>
          <!-- <el-table-column
            prop="addTime"
            label="库存"
            :show-overflow-tooltip="true"
          >
          </el-table-column> -->
          <el-table-column
            prop="hotOrder"
            label="套餐排序"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="mergeFlag"
            label="融合"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.mergeFlag == 0">不可融合</span>
              <span v-if="scope.row.mergeFlag == 1">可融合</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="buyCount"
            label="购买人数"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="divideNum"
            label="分成比例"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="senior"
            label="大四标识"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.senior == 0">否</span>
              <span v-if="scope.row.senior == 1">是</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="freshman"
            label="大一标识"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.freshman == 0">否</span>
              <span v-if="scope.row.freshman == 1">是</span>
            </template>
          </el-table-column>
          <el-table-column
            width="140"
            align="center"
            prop="isActive"
            label="是否开启橱窗展示"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-switch 
                v-model="scope.row.isActive"
                :active-value=1
                @change="loadItem(scope.row)"
                :disabled="scope.row.isActive == 1?true:false"
              >
              </el-switch>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="discount"
            label="用户折扣"
            :show-overflow-tooltip="true"
          >
          </el-table-column> -->
          <el-table-column
            align="right"
            label="操作"
            width="140"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-search"
                @click="handleDetails(scope.row)"
                title="详情"
              ></el-button>
              <el-button
                size="mini"
                type="primary"
                @click="handleForm(true, scope.row, afterHandleForm)"
                icon="el-icon-edit"
                title="编辑"
              ></el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                :disabled="scope.row.status == 1"
                @click="handleDelete({ id: scope.row.id })"
                title="删除"
              ></el-button>
              <el-popover placement="top" trigger="click">
                <div style="text-align: right">
                  <el-button
                    v-if="scope.row.spreadStatus == 0"
                    size="mini"
                    type="danger"
                    @click="handlePromotion(scope.row)"
                    icon="el-icon-s-promotion"
                    title="推广"
                  ></el-button>
                  <el-button
                    v-if="scope.row.spreadStatus == 1"
                    size="mini"
                    type="danger"
                    @click="stopPromotion(scope.row.id)"
                    icon="el-icon-turn-off"
                    title="停止推广"
                  ></el-button>
                </div>
                <el-button size="mini" slot="reference" icon="el-icon-more" style="margin-left: 5px"></el-button>
              </el-popover>
              <!-- <el-popover placement="top" trigger="click">
                <div style="text-align: right">
                  <el-button size="mini" class="handle"  >批量上架</el-button>
                  <el-button size="mini" class="handle"  >批量下架</el-button>
                </div>
                <el-button size="mini" slot="reference" icon="el-icon-more" style="margin-left: 5px"></el-button>
              </el-popover> -->
            </template>
          </el-table-column>
        </paged-table>
      </div>
    </area-tree>
  <!-- 添加和编辑界面 -->
    <el-dialog :title="isEditor ? '编辑套餐' : '添加套餐'" :before-close="resetForm" :visible.sync="aVisible" width="800px" :close-on-click-modal="false" @close = "closeVisible">
      <el-form label-width="110px" ref="formFileds" :model="form" :rules="formRules" size="small" >
        <el-form-item  label="学校：" v-if="sessionIsAdmin =='1'" prop="schoolId" style="width: 60%">
          <el-select  v-model="form.schoolId" @change="fetchSchoolDicts" clearable filterable placeholder="请选择学校"> <el-option v-for="(item,index) in schoolAll" :label="item.schoolName" :value="item.id" :key="index" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="选择校区：" v-if="sessionIsAdmin =='1'" prop="distId" style="width: 60%">
          <el-select v-model="form.distId" clearable filterable placeholder="请选择校区">
              <el-option v-for="(item,index) in schoolDicts" :label="item.distName"  :value="item.id" :key="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="sessionIsAdmin =='1'" label="运营商：" prop="operator" style="width: 60%">
          <el-select v-model="form.operator" clearable filterable placeholder="请选择合运营商">
            <el-option label="中国移动" value="中国移动"></el-option>
            <el-option label="中国联通" value="中国联通"></el-option>
            <el-option label="中国电信" value="中国电信"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐名称：" prop="name" style="width: 60%">
          <el-input placeholder="请输入套餐名称" v-model="form.name" :disabled="isEditor" clearable></el-input>
        </el-form-item>
        <el-form-item label="上传照片:" prop="iconUrl">
          <base-upload v-model="form.iconUrl" fileType="single" @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片尺寸800PX×800PX以上，文件大小在5MB以内,图片格式只支持：JPEG、JPG、PNG、GIF</div>
        </el-form-item>
         <!-- <el-form-item label="详情图片:" prop="detailImg">
          <base-upload v-model="form.detailImg" fileType="single" @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片尺寸800PX×800PX以上，文件大小在5MB以内,图片格式只支持：JPEG、JPG、PNG、GIF</div>
        </el-form-item> -->
        <el-row>
        <el-col :span="8">
          <el-form-item label="套餐标签" prop="labelId"  >
            <el-select v-model="form.labelId" @focus="fetchLabelAll" clearable filterable placeholder="请选择套餐标签">
              <el-option 
              v-for="(item,index) in labelAll"
              :label="item.name" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
         </el-col>
        <el-col :span="4">
          <el-form-item label-width='20px' prop="iconUrl"  >
            <el-popover
              placement="bottom"
              title="自定义标签"
              width="400"
              trigger="manual"
              v-model="visible">
                <el-form label-width="110px" ref="formFileds" :model="selfForm"  size="small">
                  <el-form-item label="标签名称：" prop="name" >
                    <el-input v-model="selfForm.name" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="标签描述：" prop="description"   >
                    <el-input v-model="selfForm.description" type="textarea"  :rows="4"  clearable></el-input>
                  </el-form-item>
                </el-form>
                
                  <el-button size="small" @click="visible = false">取消</el-button>
                  <el-button size="small" type="primary" :loading="bLoading" @click="fetchSelfLabel">保存</el-button>
                
              <el-button slot="reference" @click="visible = !visible">自定义标签</el-button>
            </el-popover>
          </el-form-item>
        </el-col>
        </el-row>
        <el-form-item label="带宽" prop="bandWidth" style="width: 60%">
          <el-input placeholder="请输入带宽" v-model="form.bandWidth" :disabled="isEditor" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="热点编码" prop="keyWord" style="width: 60%" >
          <el-input placeholder="请输入热点编码" v-model="form.keyWord" :disabled="isEditor" clearable></el-input>
        </el-form-item>
        <el-form-item label="原价（元）" prop="price"   style="width: 60%">
          <el-input placeholder="请输入原价（元）" v-model="form.price" oninput = "value=value.replace(/[^\d^\.]/g,'')"  clearable></el-input>
        </el-form-item>
        <el-form-item label="售卖价（元）" prop="realyPrice" style="width: 60%">
          <el-input placeholder="请输入售卖价（元）" v-model="form.realyPrice" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="分成比例" prop="divideNum" style="width: 60%">
          <el-input placeholder="请输入分成比例" v-model="form.divideNum" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="外部排序" prop="hotOrder" style="width: 60%">
          <el-input placeholder="请输入外部排序" v-model="form.hotOrder" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')"></el-input>
        </el-form-item>
        <!-- <el-form-item label="内部排序" prop="innerOrder" style="width: 60%">
          <el-input v-model="form.innerOrder" clearable></el-input>
        </el-form-item> -->
        <el-form-item label="套餐描述" prop="description" style="width: 60%">
          <el-input placeholder="请输入套餐描述" v-model="form.description" clearable type="textarea"  :rows="4"></el-input>
        </el-form-item>
        <el-form-item label="套餐状态" prop="status" style="width: 60%" >
          <el-radio-group v-model="form.status">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="0">下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生效规则" prop="activityRule" style="width: 60%">
          <el-input placeholder="请输入生效规则" v-model="form.activityRule" clearable></el-input>
        </el-form-item>
        <el-form-item label="融合标识" prop="mergeFlag" style="width: 60%">
          <el-radio-group v-model="form.mergeFlag">
            <el-radio :label="1">可融合</el-radio>
            <el-radio :label="0">不可融合</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="大四标识" prop="senior" style="width: 60%">
          <el-radio-group v-model="form.senior">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="大一标识" prop="freshman" style="width: 60%">
          <el-radio-group v-model="form.freshman">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="立即缴费时的用户折扣值" prop="discount" style="width: 60%">
          <el-input placeholder="请输入立即缴费时的用户折扣值" v-model="form.discount" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="套餐时长（天）" prop="lastTime" style="width: 60%">
          <el-input placeholder="请输入套餐时长（天）" v-model="form.lastTime" clearable oninput = "value=value.replace(/[^\d^\.]/g,'')"></el-input>
        </el-form-item>
        <!-- <el-form-item label="加入首页橱窗" prop="isActive" style="width: 80%" v-if="isEditor" >
          <el-radio-group v-model="form.isActive" @change="form.isActive == 1?innerVisible = true:innerVisible = false" >
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <!-- <el-form-item  label="选择学校" prop="schoolId" style="width: 60%">
          <el-select  v-model="form.schoolId" @change="fetchSchoolDicts" clearable filterable placeholder="请选择学校">
              <el-option 
              v-for="(item,index) in schoolAll"
              :label="item.schoolName" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item  label="选择校区" prop="distId" style="width: 60%">
          <el-select   v-model="form.distId" @focus="fetchSchoolDicts" clearable filterable placeholder="请选择校区">
              <el-option 
              v-for="(item,index) in schoolDicts"
              :label="item.distName" 
              :value="item.id"
              :key="index"
              ></el-option>
            </el-select>
        </el-form-item> -->
        <el-form-item label="内容" prop="content">
          <tinymce v-if="aVisible"  v-model="form.content"></tinymce>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi,formChange,resetForm) : submitForm(insertApi,formChange,resetForm)">保存</el-button>
      </div>
    </el-dialog>
  <!-- 推广套餐 -->
    <el-dialog title= '推广套餐' :visible.sync="bVisible" width="800px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="formFileds" :model="form" :rules="formRules" size="small">
        
        <el-form-item label="推广文案：" prop="name" style="width: 60%">
          <el-input v-model="form.name" type="textarea"  :rows="4" clearable></el-input>
        </el-form-item>
        <el-form-item label="上传照片:" prop="iconUrl">
          <base-upload v-model="form.iconUrl" :limit="6"  @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片格式只支持：JPEG、JPG、PNG、ICO(最多6张图片)</div>
        </el-form-item>
        
        <el-form-item label="分销总金额（元）：" prop="icopricenUrl" label-width='140px' style="width: 60%">
          <el-input v-model="form.price" clearable></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="一级佣金（元）：" prop="realyPrice" label-width='140px' >
              <el-input v-model="form.realyPrice" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="二级佣金（元）：" prop="lastTime" label-width='140px' >
              <el-input v-model="form.lastTime" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="三级佣金（元）：" prop="iconUrl" label-width='140px'>
              <el-input v-model="form.iconUrl" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">确定并推广</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog title="套餐详情" :visible.sync="bVisible" width="800px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details"  size="small">
        <el-form-item label="运营商：" prop="operator" style="width: 60%">
            <span v-text="details.operator" clearable></span>
        </el-form-item>
        <el-form-item label="套餐名称：" prop="name" style="width: 60%">
          <span v-text="details.name" clearable></span>
        </el-form-item>
        <el-form-item label="图标:" prop="iconUrl">
          <img :src="details.iconUrl" alt="">
        </el-form-item>
        <!-- <el-form-item label="详情图片:" prop="detailImg">
          <img :src="details.detailImg" alt="">
        </el-form-item> -->
        <el-form-item label="带宽:" prop="bandWidth"  >
            <span v-text="details.bandWidth" clearable></span>
        </el-form-item>
        <el-form-item label="城市热点编号:" prop="keyWord"  >
            <span v-text="details.keyWord" clearable></span>
        </el-form-item>
        <el-form-item label="原价(元):" prop="price" style="width: 60%">
          <span v-text="(details.price/100).toFixed(2)" clearable></span>
        </el-form-item>
        <el-form-item label="售卖价(元):" prop="realyPrice" style="width: 60%">
          <span v-text="(details.realyPrice/100).toFixed(2)" clearable></span>
        </el-form-item>
        <el-form-item label="分成比例:" prop="divideNum" style="width: 60%">
          <span v-text="details.divideNum" clearable></span>
        </el-form-item>
        <el-form-item label="外部排序:" prop="hotOrder" style="width: 60%">
          <span v-text="details.hotOrder" clearable></span>
        </el-form-item>
        <el-form-item label="套餐描述:" prop="description" style="width: 60%">
          <span v-text="details.description" clearable></span>
        </el-form-item>
        <el-form-item label="套餐状态:" prop="status" style="width: 60%">
          <span v-if="details.status == 1" clearable>上架</span>
          <span v-if="details.status == 0" clearable>下架</span>
        </el-form-item>
        <el-form-item label="生效规则:" prop="activityRule" style="width: 60%">
          <span v-text="details.activityRule" clearable></span>
        </el-form-item>
        <el-form-item label="是否大四:" prop="senior" style="width: 60%">
          <span v-if="details.senior == 1" clearable>是</span>
          <span v-if="details.senior == 0" clearable>否</span>
        </el-form-item>
        <el-form-item label="是否大一:" prop="freshman" style="width: 60%">
          <span v-if="details.freshman == 1" clearable>是</span>
          <span v-if="details.freshman == 0" clearable>否</span>
        </el-form-item>
        <el-form-item label="套餐天数:" prop="lastTime" style="width: 60%">
          <span v-text="details.lastTime" clearable></span>
        </el-form-item>
        <el-form-item label="用户折扣值:" prop="discount" style="width: 60%">
          <span v-text="details.discount" clearable></span>
        </el-form-item>
        <el-form-item label="学校名称:" prop="extend.schoolName" style="width: 60%">
          <span v-text="details.extend.schoolName" clearable></span>
        </el-form-item>
        <el-form-item label="校区:" prop="distName" style="width: 60%">
          <span v-text="details.distName" clearable></span>
        </el-form-item>
        <el-form-item label="套餐内容:" prop="description" style="width: 60%">
          <div v-html="details.content" ></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="bVisible = false">取消</el-button>
        <!-- <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button> -->
      </div>
    </el-dialog>
    <!-- 推广 -->
    <el-dialog title="推广套餐" :visible.sync="promotionVisible" width="900px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="promotionForm" :model="promotionForm" :rules="promotionRules" size="small">
        <el-form-item label="分销文案：" prop="note" style="width: 100%">
          <tinymce v-model="promotionForm.note"></tinymce>
        </el-form-item>
        <el-form-item label="商品图片：" prop="ids">
          <base-upload v-model="promotionForm.ids"  fileType="multiple"  :limit="3" :uploadData="uploadData" @beforeUpload="handleBeforeUpload"></base-upload>
          <div class="tips">图片尺寸800PX×800PX以上，文件大小在5MB以内,图片格式只支持：JPEG、JPG、PNG、GIF</div>
        </el-form-item>
        <el-form-item label="分销需求量：" prop="demand"> 
          <el-input v-model.number="promotionForm.demand" placeholder="请输入分销需求量" clearable></el-input>
        </el-form-item>
        <el-form-item label="分销总金额：" prop="goodsCommission">
          <el-input v-model.number="promotionForm.goodsCommission" placeholder="请输入佣金" clearable>
            <template slot="append">元</template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <div class="edit">
            <el-button class="btn" @click="promotionVisible = false">取消</el-button>
            <el-button class="btn submit" v-if="promotionForm.status == null" @click="addPromotion('promotionForm')">暂存</el-button>
            <el-button class="btn submit" v-if="promotionForm.status == 1" @click="updatePromotion('promotionForm')">提交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 橱窗推广 -->
    <el-dialog
    width="30%"
    title="橱窗推广"
    :visible.sync="innerVisible"
    @close="innerVisible = false,refresh()"
    append-to-body>
      <div class="pay">
          <p>请选择支付方式:</p>
          <div class="cont">
              <div class="payType" :class="payIndex == index?'active':''" v-for="(item,index) in payType" :key="index" @click="payIndex = index">
                  <img :src="item.imageUrl" alt="">
              </div>
          </div>
          <div class="payNext">
              <!-- <p>点击下一步同意 <span>《校园CEO大赛充值服务协议》</span></p> -->
              <div class="edit">
                  <el-button class="btn" @click="innerVisible = false,refresh()">取消</el-button>
                  <el-button class="btn submit" @click="payment">下一步</el-button>
              </div>
          </div>
      </div>
      <!-- 微信二维码 -->
      <el-dialog title="微信支付" :visible.sync="jVisible" center width="400px" append-to-body>
          <img :src="wxpayUrl" alt="" style="margin:0px auto;display:block;width:300px;height:300px">
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions,mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import Tinymce from "@/tinymce";
import { log, mergeObject } from '@/common/utils'
import QRCode from 'qrcode'


export default {
  name:'broadBand',
  components: {
    PagedTable,
    BaseUpload,
    AreaTree,
    Tinymce
  },
  mixins: [pageMixin,provinces,download],
  data() {
    let checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('佣金不能为空'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      wxpayUrl:'',
      jVisible:false,
      timer:null,
      activeMealId:'',
      activeShopId:'',
      innerVisible: false,
      url:'',
      visible: false,
      BaseUrl,
      isChecked:false,
      queryParams: {
        pageNum:1,
        pageSize:10,
        status:'',
        schoolId:'',
        name:'',
      },
      // 批量上架/下架
      BatchStatusChange:{
        ids:'',
        status:''
      },
     select1:'1',
     select2:'',
      // 所有学校
      schoolAll:[],
      // 所有校区
      schoolDicts:'',
      // 所有标签
      labelAll:[],
      //自定义标签
      selfForm:{
        name:'',
        description:'',
        id:''
      },
      schoolType: [],
      labelForm:{
        name:'',
        description:'',
        id:''
      },
      sessionIsAdmin: null,
      form:{
        id: null,
        detailImg:'',
        shopId:'',
        addUserId:'',
        buyCount:'',
        labelName:'',
        labelId:'',
        schoolId:'',
        distId:'',
        name:'',
        iconUrl:'',
        operator:'',
        bandWidth:'',
        keyWord:'',
        price:'',
        realyPrice:'',
        divideNum:'',
        hotOrder:'',
        innerOrder:'',
        description:'',
        content:'',
        status:1,
        activityRule:'',
        mergeFlag:1,
        senior:1,
        freshman:1,
        isActive: 0,
        discount:'',
        lastTime:'',
      },
      NewsDialogVisible:false,
      details:{
        extend:{},
        content:'',
        distName:'lalal'
      },
      formRules: {
        name: [{ required: true, message: "请输入资料名称", trigger: "blur" }],
        iconUrl: [{ required: true, message: "请上传图片", trigger: "blur" }],
        operator: [{ required: true, message: "请选择运营商", trigger: "blur" }],
        schoolId: [{ required: true, message: "请选择学校", trigger: "blur" }],
        distId: [{ required: true, message: "请选择校区", trigger: "blur" }],
        price: [{ required: true, message: "请输入原价", trigger: "blur" }],
        realyPrice: [{ required: true, message: "请输入售卖价", trigger: "blur" }],
        monthPrice: [{ required: true, message: "请输入月租", trigger: "blur" }],
        bandWidth: [{ required: true, message: "请输入带宽", trigger: "blur" }],
        status: [{ required: true, message: "请选择套餐状态", trigger: "blur" }],
        mealId: [{ required: true, message: "请选择宽带名称", trigger: "blur" }],
        groupFlag: [{ required: true, message: "请选择是否团购", trigger: "blur" }],
        groupNum: [{ required: true, message: "请选择团购人数", trigger: "blur" }],
        isActive: [{ required: true, message: "请选择是否加入首页橱窗", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      // 推广 分销
      promotionVisible: false,
      promotionForm: {
        goodsId:null,
        note:'',
        goodsCommission: null,
        demand:null,
        ids:[],
      },
      // 分销
      promotionRules: {
        ids:[{ required: true, message: '请上传商品图片', trigger: 'change' }],
        demand:[{ required: true, message: '请输入分销需求量', trigger: 'blur' },
        {type:'number',message:'需求量必须为数值', trigger: 'blur'}],
        note:[{ required: true, message: '请输入分销文案', trigger: 'blur' },],
        goodsCommission:[{ required: true, message: '请输入佣金', trigger: 'blur' },
        { validator:checkNum,trigger: 'blur'  }],
      },
      // 上传图片格式
      uploadData:{
				bizType:'',
				bizId:'',
				needStore:'1',
			},
      // 支付类型
      payType:[
          {
              type:1,
              imageUrl: require('@/assets/images/alpay.jpg')
          },
          {
              type:2,
              imageUrl: require('@/assets/images/wechat.jpg')
          }
      ],
      payIndex:null,


      pageApi:'getBroadBand',
      insertApi:'getBroadBandAdd',
      editorApi:'getBroadBandEdit',
      deleteApi:'getBroadBandDelete',
      loadApi:'getBroadBandLoad',
      
    };
  },
  computed: {
    ...mapState("broadBand", ["broadBandList", "broadBandTotal"]),
  },
  methods: {
    ...mapActions("broadBand",[
      "getBroadBand",
      "getBroadBandSelect",
      "getBroadBandAdd",
      "getBroadBandEdit",
      "getBroadBandLoad",
      "getBroadBandDelete",
      "getSchoolAll",
      "getSchoolDicts",
      "getBatchStatusChange",
      'stopPromote',
      'promoteInfo',
      'storePromote',
      'updatePromote',
      'showcasePay',
      'paySuccess'
      ]),
       ...mapActions("goodsLable",[
       "getGoodsLableList",
       "getGoodsLableAdd"
       ]),
       ...mapActions('card',['addShopPromotion']),
    formChange(params){
      console.log(params)
      params.price = params.price*100
      params.realyPrice = params.realyPrice*100
      return true;
    },
    async afterHandleForm(){
      this.form.price = this.form.price/100
      this.form.realyPrice = this.form.realyPrice/100
      console.log(this.form)
      const res = await this.getSchoolDicts({schoolId:this.form.schoolId})
      this.schoolDicts = res
    },
    select(){
     if(this.select1 == 1){
       this.queryParams.schoolId = ''
       this.queryParams.name = this.select2
       console.log(1);
     }
     if(this.select1 == 2){
       this.queryParams.name = ''
       this.queryParams.schoolId = this.select2
       console.log(2);
     }
     this.refresh()
   },
  
    // 查找
    selectBtn(){
      this.refresh()
    },
    // 获取学校
     async fetchSchoolAll() {
        const res = await this.getSchoolAll({})
        this.schoolAll = res
        console.log(res,"xuexiao");
    },
    // 获取校区
     async fetchSchoolDicts() {
       this.form.distId = ''
        const res = await this.getSchoolDicts({schoolId:this.form.schoolId})
        this.schoolDicts = res
    },
    afterFun(row) {
      this.fetchSchoolDicts(row.schoolId)
    },
    // 获取标签
     async fetchLabelAll() {
       let obj = {
         pageNum:1,
         pageSize:10,
       }
        const res = await this.getGoodsLableList(obj)
        this.labelAll = res.list
        console.log(res,"标签");
    },
    // 自定义标签
      async fetchSelfLabel() {
        this.visible = false
        const res = await this.getGoodsLableAdd(this.selfForm)
        this.fetchLabelAll()
        this.selfForm = ''
    },
    // 打开checked
    openCheckedUp(){  //上架
      this.isChecked = true
      this.BatchStatusChange.status = 1
    },
    openCheckedDown(){  //下架
      this.isChecked = true
      this.BatchStatusChange.status = 0
    },
    // 关闭checked
    closeChecked(){
      this.isChecked = false
       this.BatchStatusChange.status = ''
       this.BatchStatusChange.ids = ''
    },
    // 确定checked
    async enterChecked(){
      const res = await this.getBatchStatusChange(this.BatchStatusChange)
      this.refresh()
      this.isChecked = false
    },
    // 选择的ids
    handleSelectionChange(val) {
      let arr = []
      let idArr = []
      let ids = ''
      arr = val.map((item,index) => {
        return Object.assign({},{'ids':item.id})
      })
      for(var i in arr){
        for(var j in arr[i]){
          idArr.push(arr[i][j])
        }
      }
      ids = idArr.toString()
      this.BatchStatusChange.ids = ids
    },
    //关闭添加内部弹框
    closeVisible(){
      this.visible = false
    },
    // 图片上传
     handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png') || file.name.includes('gif');
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isPicture) {
        this.$message.error('上传图片只能是 JPG/PNG/gif 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      callback(isPicture && isLt2M)
    },
    handleQuery(data){
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
        this.queryParams.city = null;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
      if ((data.id + "").length <9) {
        this.queryParams.schoolId = data.id;
      }
      this.refresh()
    },
    resetForm(){
      this.$refs.formFileds.resetFields()
      this.form.id = null
      this.aVisible = false
    },
    // 添加分销
    async handlePromotion(row) {
      let res = await this.promoteInfo({goodsId:row.id})
      this.promotionForm = {
        goodsId: row.id,
        ids:res.picList,
        note:res.note,
        goodsCommission: parseFloat(res.goodsCommission/100),
        demand:res.demand,
        status:res.status
      }
      console.log(this.promotionForm)
      this.promotionVisible = true
    },
    // 添加分销
    addPromotion(formName) {
      this.$refs[formName].validate(async(valid) => {
          if (valid) {
            let {status,...params} = this.promotionForm
            params.goodsCommission = this.promotionForm.goodsCommission*100
            params.ids = this.promotionForm.ids.map(item => item.id)
            console.log(params)
            let res = await this.storePromote(params)
            if (res != null) {
              this.promotionVisible = false
              this.$message.success('暂存分销成功')
              this.refresh()
            }
          } else {
            return false;
          }
        });
    },
    // 上架分销
    updatePromotion(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          let {status,...params} = this.promotionForm
          params.goodsCommission = this.promotionForm.goodsCommission*100
          params.ids = this.promotionForm.ids.map(item => item.id)
          console.log(params)
          let res = await this.updatePromote(params)
          if (res == null) {
            this.promotionVisible = false
            this.$message.success('提交分销成功')
            this.refresh()
          }
        } else {
          return false;
        }
      });
    },
    // 停止分销
    stopPromotion(id) {
       this.$confirm('确认下架该分销商品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let res =  await this.stopPromote({goodsId:id})
          if (res != null) {
            this.$message.success('停止推广成功')
            this.refresh()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    //  橱窗推广  支付
    // 
    loadItem(item){
      if (item.isActive == 1) {
        this.innerVisible = true
        //
        this.activeMealId = item.id
        this.activeShopId = item.shopId
      }else{
        this.innerVisible = false
      }
    },
    // 充值
    async payment() { 
        let params = {
            payType:this.payType[this.payIndex].type,
            mealId: this.activeMealId,
            shopId: this.activeShopId,
            mealType:1,
            hallType: this.url?1:2
        }
        if (this.payType[this.payIndex].type == 1) {
            // 支付宝 1
           let routeUrl = this.$router.resolve({
             path:'/aliPay',
             query:params
           })
            window.open(routeUrl.href,'_blank')
            this.payStatus()
        }else{
              // 微信 2
            //this.innerVisible = false
            this.jVisible = true
            let res = await this.showcasePay(params)
            localStorage.setItem('orderId',res.orderNum)
            // 转二维码
            QRCode.toDataURL(res.form).then((url)=>{
                this.wxpayUrl = url
            }).catch(err => {
                console.error(err)
            })
            this.payStatus()
        }
    },
    // 查询支付状态
    async payStatus(){
        let res = await this.paySuccess({outTradeNo:localStorage.getItem('orderId'), type:1})
        if(res == true){
            this.$message.success('充值成功')
            clearInterval(this.timer)
            this.innerVisible = false
            this.jVisible = false
            this.timer = null
            this.refresh()
        }else{
          if (this.innerVisible == true || this.jVisible == true) {
            setTimeout(this.payStatus, 3000)
          }else{
            this.refresh()
          }
        }
    },
  },
  created(){
  },
  mounted(){
    this.refresh()
    this.sessionIsAdmin = sessionStorage.getItem('isAdmin')
    this.fetchSchoolAll()
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033')
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.item-line {
  display: inline-block;
  .w100 {
    width: 170px;
  }
}
.edit {
  .btn {
    margin-right: 20px;
    width: 140px;
    &.submit {
      color: $c-white;
      background-color: $c-success;
    }
  }
}

.pay {
    >p {
        font-size: 16px;
        color: #222;
    }
    .cont {
        display: flex;
        justify-content: space-around;
        .payType {
            margin: 10px;
            width: 200px;
            height: 70px;
            border: 1px solid #EAEFF3;
            border-radius: 6px;
            img {
                margin: 7px auto;
                width: 150px;
                height: 55px;
                display: block;
            }
            &.active {
                position: relative;
                border: 1px solid #28B892;
            }
        }
        .active::after {
            content: '';
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            bottom: 0;
            right: 0;
            background: url('../.././assets/images/money_active.png');
        }
    }
    .payNext {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        >p {
            line-height: 36px;
            margin-left: 100px;
            font-size: 14px;
            color: #AFAFAF;
            >span {
                color: #4A90E2;
            }
        }
        &>p::before {
            content: '*';
            margin-right: 5px;
            color: #FF5353;
        }
        .edit {
          width: 100%;
          display: flex;
          justify-content: center;
            .btn {
                width: 140px;
                &.submit {
                    color: #fff;
                    background-color: #28B892;
                }
            }
        }
    }
}
</style>
